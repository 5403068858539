/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: 'Open Sans', sans-serif;
  color: #444444;
  background-color: rgb(241, 241, 241);
}

.react-calendar {
  margin-left: 200px;
}

main.container-fluid {
  padding: 0;
}

a {
  color: #2273c9 !important;
}

a:hover {
  color: #2273c9;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #b3921c;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #2273c9;
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
}

#header .logo h1 {
  font-size: 26px;
  padding: 0 0 0 8px;
  margin: 11px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  border-left: 8px solid #b3921c;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #545454;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #545454 !important;
  padding: 5px 0 15px 25px;
  transition: 0.3s;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.nav-menu a.active {
  color: #b3921c !important;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  color: #b3921c !important;
  text-decoration: none;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 20px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
  color: #b3921c !important;
  cursor: pointer;
}

.nav-menu .drop-down > a:after {
  content: '\ea99';
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: '\eaa0';
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: '\ea9d';
  }
}

/* Mobile Navigation */
.navbar-collapse.collapse.show {
  margin-top: 17px;
  background-color: white;
}

.navbar-collapse.collapsing {
  margin-top: 17px;
  background-color: white;
}

.logout:hover {
  cursor: pointer;
  color: rgb(168, 152, 3);
}

.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 20px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #b3921c;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #545454 !important;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #b3921c !important;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: '\ea99';
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: '\eaa1';
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(59, 59, 59, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/* container */

.main-cont {
  padding-top: 80px;
  background-color: rgb(241, 241, 241);
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 0 0 10px 0;
  padding-top: 50px;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #2273c9;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Featured
--------------------------------------------------------------*/

.featured {
  padding-top: 50px;
  position: relative;
  z-index: 2;
  background-color: rgb(241, 241, 241);
}

.icon-box {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.87);
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  background-color: white;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.089);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.089);
  box-shadow: 6px 8px 6px -6px rgba(0, 0, 0, 0.089);
}

@media (max-width: 991px) {
  .icon-box {
    min-height: auto;
  }

  .icon-box .box-btn {
    position: relative;
  }
}

.icon-box p {
  margin-top: 12px;
}

.icon-box .image {
  height: 150px;
  /* background-image: url('../img/5k-stub.jpg'); */
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
/* 
.icon-box.twotwok .image {
	background-image: url("../img/22k-stub.jpg");
}
.icon-box.twotwok-team .image {
	background-image: url("../img/22kteam-stub.jpg");
} */
.icon-box.fivek h2 {
  font-size: 26px;
  font-weight: 700;
}
.icon-box.twotwok-team h2 {
  font-size: 30px;
}

.icon-box .round-image {
  position: absolute;
  right: 0;
  left: 0;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.836);
  /* padding-left: 30px;
	padding-right: 30px; */
  color: #fff;
  font-size: 0px;
}

.icon-box .text-block {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}

button.box-btn {
  color: #ffffff;
  bottom: 0;
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: #2273c9;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  width: 35%;
}

button.box-btn:hover {
  background-color: #19589b;
  color: white;
  cursor: pointer;
}

.icon-box i {
  color: #b3921c;
  font-size: 42px;
  margin-bottom: 15px;
  display: block;
}

.icon-box h3 {
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 50px;
  text-align: center;
}

.icon-box h3 {
  color: #dbc163;
  transition: 0.3s;
}

.icon-box h2 {
  color: #535353;
  font-weight: 600;
  transition: 0.3s;
  margin-top: 15px;
  margin-bottom: 5px;
}

.icon-box p {
  color: #535353;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.icon-box:hover {
  background-position: top;
}

.icon-box:hover i,
.icon-box:hover h3 a,
.icon-box:hover p {
  /* color: #fff; */
}

/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/
.clients {
  background-color: rgb(241, 241, 241);
}

.clients .row img {
  margin-top: 5px;
}

.clients .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.clients .owl-item img {
  opacity: 0.5;
  width: 80%;
  transition: 0.3s;
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}

.clients .owl-item img:hover {
  -webkit-filter: none;
  filter: none;
  opacity: 1;
}

.clients .owl-nav,
.clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.clients .owl-dot.active {
  background-color: #e96b56 !important;
}

/*--------------------------------------------------------------
# Our Guarantees
--------------------------------------------------------------*/
.guarentees {
  background-color: #ffffff;
}

.guarentees .row img {
  width: 150px;
}
/* 
.guarentees .row .image {
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: url('../img/sponzor.png');
} */

.guarentees.media {
  background-color: rgb(241, 241, 241);
}

.guarentees.media .row img {
  width: 150px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  margin-top: 80px;
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #b3921c;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: #b3921c;
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type='submit'] {
  background: #b3921c;
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type='submit']:hover {
  background: #2273c9;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #404040;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #404040;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type='email'] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

#footer .footer-newsletter form input[type='submit'] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #b3921c;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type='submit']:hover {
  background: #2273c9;
}

#footer .footer-top {
  background: #3b3b3b;
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #b3921c;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  margin-top: 20px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ec7f6d;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff !important;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #b3921c !important;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

/*TIMER*/

.row.timer {
  margin: 0 auto;
  color: white;
  text-align: center;
  margin-top: 20px;
}

.row.timer .times {
  margin: 0 auto;
  text-align: bottom;
}

.timer .time {
  float: left;
  display: inline;
  font-size: 3em;
  border: 3px solid rgba(0, 0, 0, 0.562);
  padding: 25px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.356);
}

.timer .colon {
  font-size: 24px;
}

@media (max-width: 648px) {
  .timer .time {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .timer .time {
    font-size: 1.5em;
    margin-bottom: 2px;
    padding: 10px;
  }
}

@media (max-width: 330px) {
  .timer .time {
    font-size: 2em;
    display: block;
    float: none;
    margin-bottom: 2px;
    padding: 10px;
  }
}

/* BASIC INFO SECTION */

.basic-info {
  background-color: rgb(241, 241, 241);
}

@media (max-width: 992px) {
  .basic-info h4 {
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/

.packages {
  background-color: white;
}

/* ABOUT SECTION - TRACK INFO*/
.fancy-list ul {
  list-style: none;
}

.fancy-list ul li::before {
  content: '\2022';
  color: #ec7f6d;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  display: inline-block;
  font-size: 18px;
}

.about .content {
  padding-left: 30px;
  margin-bottom: 30px;
}

.interactive-track iframe {
  width: 100%;
  height: 250px;
}

.interactive-track video {
  width: 100%;
  height: 250px;
}

/* .interactive-track video {
  width: 100%;
	height: 50vh;
	margin-left: 15px;
	margin-right: 17px;
	margin-bottom: 20px;
} */

.elevation-img {
  text-align: center;
}

.elevation-img img {
  width: 100%;
}

.track-video iframe {
  width: 100%;
  height: 50vh;
  margin-left: 15px;
  margin-right: 17px;
  margin-bottom: 20px;
}

/* PACKAGES / REGISTER INFO */

.package .register-price-info {
  color: gray;
  padding-bottom: 10px;
}

.register .icon-box .text-block {
  padding-left: 15px;
  padding-right: 15px;
}

.package-info {
  margin-top: 10px;
  color: #797979;
}

.package-info hr {
  margin-top: 2px;
  margin-bottom: 2px;
}

.register a:hover {
  cursor: pointer;
}

.icon-box.package {
  min-height: auto;
}

.icon-box.package .box-btn {
  position: relative;
  width: 100%;
  margin-top: 10.5px;
  padding: 8px;
  font-size: 18px;
  background-color: gray;
  /* background-color: #2273c9; */
}

.icon-box.package .box-btn:hover {
  cursor: default;
  /* background-color: rgb(197, 57, 111); */
  /* cursor: pointer; */
}

.register .icon-box .price {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  background-color: rgb(241, 240, 240);
  color: rgb(73, 73, 73);
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* ABOUT US PAGE */

.about-goal {
  background-color: rgb(241, 241, 241);
}

.we-are .short-info {
  margin-top: 50px;
}

.we-are img {
  border-radius: 50%;
  width: 120px;
}

.we-are .person-name {
  margin-top: 15px;
  font-weight: 600;
  font-size: 24px;
}

.we-are .person-title {
  margin-top: -10px;
}

.we-are .person-text {
  margin-top: 25px;
}

/* RULES PAGE */

.rules-details {
}

.rules-categories {
  background-color: rgb(241, 241, 241);
}

/* Rewards */

.rewards h1 {
  font-size: 48px;
}

.rewards-table {
  margin-left: auto;
  margin-right: auto;
}

.rewards-table td {
  font-size: 24px;
  font-weight: bold;
}

.rewards-table .price.first {
  color: #c5a90d;
}

.rewards-table .price.second {
  color: #a5a5a5;
}

.rewards-table .price.third {
  color: #cd7f32;
}

/* categories rules */

.rules-categories .category-table {
  margin-left: auto;
  margin-right: auto;
}

.category-table td {
  margin: 0;
}

.category-table .head {
  font-size: 24px;
  font-weight: bold;
}

.category-table tr {
  font-size: 20px;
}

.category-table .value {
  padding-left: 50px;
}

/* TEAM RACE */

.about .part-track-size {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold;
}

/* REGISTRATION FORM */

.registration-page.background-container {
  /* background: url("../img/park-ravne-2.jpg"); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  background-color: rgb(241, 241, 241);
  height: 100vh;
  /* position: absolute; */
}

/** overlay background */
.registration-page .image-container {
  /* background: rgba(0, 0, 0, 0.82); */
  /* overflow: hidden; */
  /* height: 100%; */
  /* z-index: 2; */
  padding: 30px;
}

.registration-page .form-container {
  /* margin: 0; */
  /* position: absolute; */
  margin-top: 100px;
  /* left: 50%; */
  /* -ms-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  /* width: 30%; */
  /* height: 100px; */
  border-radius: 5px;
  padding: 15px;
}

@media (max-width: 991px) {
  .registration-page .form-container {
    /* margin: 0; */
    /* position: absolute; */
    /* top: 70%; */
    /* left: 50%; */
    /* -ms-transform: translate(-50%, -50%); */
    /* transform: translate(-50%, -50%); */
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    /* width: 30%; */
    /* height: 100px; */
    border-radius: 5px;
    padding: 15px;
  }
}

.registration-page .form {
  margin-top: 20px;
}

.registration-page .header-text {
  font-size: 24px;
  margin-top: 10px;
  margin-left: 10px;
}

.registration-page input {
  width: 100%;
}

.registration-page .button-container {
  padding: 0;
}

.registration-page .form-row {
  display: block;
}

.registration-page .next-button {
  float: right;
}

.registration-page .next-button .btn {
  background-color: #2273c9;
  border-color: #2273c9;
}

.registration-page .next-button .btn:active {
  background-color: #2273c9 !important;
  border-color: #2273c9 !important;
}

.registration-page .radio-container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 260px;
}

@media (max-width: 270px) {
  .registration-page .radio-container {
    display: block;
  }
}

.registration-page .radio-container .radio-label {
  margin-top: 11px;
}

.registration-page .radio-container {
  margin-top: 0px;
}

.registration-page .radio-buttons {
  margin-left: 0px;
}

.registration-page .radio-container .MuiTypography-body1 {
  font-size: 14px;
}

.registration-page .radio-container .radio-label {
  width: 0px;
  display: inline-block !important;
}

.registration-page .form-header {
  margin-top: 20px;
  margin-bottom: 20px;
}

.registration-page .dropdown {
  margin-bottom: 15px;
}

.registration-page .dropdown .region-select {
  margin-top: 16px;
}

input#date {
  margin-top: 4px;
}

.form-section-title {
  margin-bottom: 15px;
  font-weight: 600;
}

.registration-page .form-buttons {
  text-align: center;
  position: relative;
}

.registration-page .form-buttons .back-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.registration-page .form-buttons .pay-button .btn {
  background-color: #2273c9;
  border-color: #2273c9;
}

.registration-page .form-buttons .pay-button .btn:active {
  background-color: #2273c9 !important;
  border-color: #2273c9 !important;
}

.registration-page .form-buttons .back-button .btn {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #2273c9;
}

.registration-page .form-buttons .back-button .btn:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #9e2d58;
}

.registration-page .form-section-notification {
  color: gray;
}

.registration-page a:hover {
  cursor: pointer;
}

/* MODAL */

/* .modal button {
  background-color: #2273c9 !important;
  border-color: #2273c9 !important;
} */

@media (min-width: 576px) {
  .modal-dialog {
    /* max-wi dth: 650px; */
    margin: 1.75rem auto;
  }
}

/* Finish page*/

.finish-button button {
  background-color: #2273c9 !important;
  border-color: #2273c9 !important;
}

.finish-button button:hover {
  background-color: #2273c9 !important;
  border-color: #2273c9 !important;
}

/* Login page */

section.login-btn {
  margin: 0;
  padding: 0;
}

.error-text.big {
  color: red;
}

div.moon-loader-wrapper {
  text-align: center;
}

/* Table */

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table th:hover {
  cursor: pointer;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #e1ebf7;
}

/** pagination */

.pagination {
  justify-content: center;
  padding-bottom: 20px;
}

.pagination > li {
  font-size: 22px;
  border: 1px solid rgb(180, 180, 180);
}

.pagination > li a {
  padding-left: 10px;
  padding-right: 10px;
}

.pagination > li.active > a {
  text-decoration: underline;
}

/** Tables **/

.table-switch {
  display: inline-block;
}

.table-switch:hover {
  cursor: pointer;
  color:rgb(88, 88, 88);
}

.result-choose:hover {
  cursor: pointer;
}